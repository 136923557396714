import type { AsyncData } from "nuxt/app";
import Bindings from "~/lib/bindings";

const { public: $env } = useRuntimeConfig();

export const bearerToken = ref<string>();
export const baseUrl = ref($env.STREAMLABS_API_BASE_PATH);

export const useStreamlabsFetch = <T>(
  relativePath: string,
  param1: Parameters<typeof useFetch<T>>[1] = {},
) =>
  useFetch<T>(
    computed(() => `${baseUrl.value}${relativePath}`),
    {
      headers: computed(() => {
        if (bearerToken.value) {
          return { Authorization: `Bearer ${bearerToken.value}` };
        }
      }),
      server: false,
      ...param1,
    },
  );

export const fetchProfiles = () =>
  useStreamlabsFetch<WidgetProfilesResponse>(
    `/api/v5/obs-plugin/widget/profiles`,
  );

export const fetchProfile = (id: number) =>
  useStreamlabsFetch<WidgetProfileResponse>(
    `/api/v5/obs-plugin/widget/profile/${id}`,
  );

export const fetchUserInfo = () =>
  useStreamlabsFetch<UserInfoResponse>(`/api/v5/obs-plugin/user/info`);

export const fetchBetaAccessStatus = useStreamlabsFetch<{ allowed: boolean }>(
  `/api/v5/obs-plugin/beta-access-status`,
  {
    immediate: false,
    lazy: true,
  },
);

export const fetchUserAccountsSettingsInfo = () =>
  useStreamlabsFetch<UserAccountSettingsInfoResponse>(
    "/api/v5/obs-plugin/user/accounts/settings-info",
  );

export const fetchOverlayInstallById = (id: number) =>
  useStreamlabsFetch<OverlayInstallResponse>(
    `/api/v5/obs-plugin/marketplace/install`,
    {
      query: { type: "overlay", id },
    },
  );

export const fetchDonationSettings = () =>
  useStreamlabsFetch<DonationSettingsResponse>(
    `/api/v5/obs-plugin/donation/settings`,
  );

export const groupedAlertTypes: {
  [groupKey: string]: {
    label: string;
    types: { key: string; label: string }[];
  };
} = {
  streamlabs: {
    label: "Streamlabs",
    types: [
      { key: "donation", label: "Donation" },
      { key: "merch", label: "Merch" },
      { key: "loyalty_store_redemption", label: "Loyalty Store Redemption" },
      {
        key: "streamlabscharitydonation",
        label: "Streamlabs Charity Donation",
      },
    ],
  },
  twitch: {
    label: "Twitch",
    types: [
      { key: "follow", label: "Follow" },
      { key: "sub", label: "Subscription" },
      { key: "resub", label: "Resub" },
      { key: "bits", label: "Bits" },
      { key: "raid", label: "Raid" },
      { key: "twitchcharitydonation", label: "Charity Donation" },
    ],
  },
  youtube: {
    label: "YouTube",
    types: [
      { key: "subscriber", label: "Subscriber" },
      { key: "sponsor", label: "Sponsor" },
      { key: "membershipGift", label: "Membership Gift" },
      { key: "fanfunding", label: "Fan Funding" },
    ],
  },
  facebook: {
    label: "Facebook",
    types: [
      { key: "facebook_share", label: "Share" },
      { key: "facebook_support", label: "Support" },
      { key: "facebook_support_gifter", label: "Support Gifter" },
      { key: "facebook_stars", label: "Stars" },
      { key: "facebook_like", label: "Like" },
      { key: "facebook_follow", label: "Follow" },
    ],
  },
  trovo: {
    label: "Trovo",
    types: [
      { key: "trovo_follow", label: "Follow" },
      { key: "trovo_sub", label: "Subscription" },
      { key: "trovo_raid", label: "Raid" },
    ],
  },
  other: {
    label: "Other",
    types: [
      { key: "pledge", label: "Patreon Pledge" },
      { key: "eldonation", label: "El Donation" },
      { key: "tiltifydonation", label: "Tiltify Donation" },
      { key: "treat", label: "Treat" },
      { key: "donordrivedonation", label: "Donor Driven Donation" },
      { key: "justgivingdonation", label: "Just Giving Donation" },
    ],
  },
} as const;

type AlertTypes = typeof groupedAlertTypes;
type PlatformTypes = AlertTypes[keyof AlertTypes]["types"];
export type AlertKey = PlatformTypes[number]["key"];

export const simulateEvent = (key: AlertKey) => {
  // useStreamlabsFetch<{
  //   data: {
  //     [groupKey: string]: {
  //       [typeKey: string]: {
  //         platform: string;
  //         title: string;
  //         type: string;
  //       };
  //     };
  //   };
  // }>("/api/v5/widgets/static/alert-types");

  useStreamlabsFetch<OverlayInstallResponse>(
    `/api/v5/widgets/desktop/test/${key}`,
    { method: "POST" },
  );
};

//
//
//

export const updateRstUserProfile = (payload: RstUserProfileResponse) => {
  return useStreamlabsFetch<RstUserProfileResponse>(
    `/api/v5/obs-plugin/rst/user/profile`,
    {
      method: "POST",
      body: { ...payload },
    },
  );
};

export const updateRstUserTarget = (
  payload: RstUserTargetsResponse[number],
) => {
  return useStreamlabsFetch<RstUserTargetsResponse>(
    `/api/v5/obs-plugin/rst/user/targets`,
    {
      method: "PUT",
      body: { ...payload },
    },
  );
};

export const deleteRstUserTarget = (
  payload: RstUserTargetsResponse[number],
) => {
  return useStreamlabsFetch<RstUserTargetsResponse>(
    `/api/v5/obs-plugin/rst/user/targets/${payload.id}`,
    {
      method: "DELETE",
    },
  );
};

export const createRstUserTarget = (payload: {
  label: string;
  streamKey: string;
}) => {
  // api.multistream.user.targets.data[index].label = nickname;
  // api.multistream.user.targets.data[index].streamKey =
  //   url + (streamKey.length ? `/${streamKey}` : "");
  // ...

  return useStreamlabsFetch<RstUserTargetsResponse>(
    `/api/v5/obs-plugin/rst/user/targets`,
    {
      method: "POST",
      body: {
        label: payload.label,
        platform: "relay",
        enabled: true,
        dcProtection: false,
        streamKey: payload.streamKey,
      },
    },
  );
};

type SignedUrlUploadTypes =
  | {
      type: "youtube-thumbnail";
      extension: "jpg" | "png" | "gif";
    }
  | {
      type: "logs";
      extension: "txt";
    };

export const generateSignedUrl = ({
  type,
  extension,
}: SignedUrlUploadTypes) => {
  return useStreamlabsFetch<{
    path: string;
    attributes: Record<string, string>;
    inputs: Record<string, string>;
  }>(`/api/v5/obs-plugin/signed-url/${type}?extension=${extension}`);
};

/**
 * @returns string Path to the log file
 */
export const uploadOBSLogs = async () => {
  console.log("getLogsReportString");
  const { content } = await Bindings.fs.getLogsReportString();

  console.log("content", content.length);

  console.log("generateSignedUrl");

  const { data } = await generateSignedUrl({
    type: "logs",
    extension: "txt",
  });

  console.log("data", data);

  const blob = new Blob([content], { type: "text/plain" });
  const file = new File([blob], "logs2.txt", { type: "text/plain" });

  const formData = new FormData();

  for (const key in data.value.inputs) {
    formData.append(key, data.value.inputs[key]);
  }

  formData.append("file", file);

  console.log("formData", formData);

  await useFetch(data.value.attributes.action, {
    method: "POST",
    body: formData,
  });

  console.log("done");

  return data.value.path;
};

//
//
//

export const fetchStreamLabelsFiles = () =>
  useStreamlabsFetch<StreamLabelsFilesResponse>(
    `/api/v5/obs-plugin/stream-labels/files`,
  );

export const fetchStreamLabelsSettings = () =>
  useStreamlabsFetch<StreamLabelsSettingsResponse>(
    `/api/v5/slobs/stream-labels/settings`,
  );

type StreamLabelAppSettingsPlatform = "twitch";

// :barf:
export const fetchStreamLabelsAppSettings = (
  platform: StreamLabelAppSettingsPlatform,
) => {
  return useStreamlabsFetch<StreamLabelsAppSettingsResponse>(
    `/api/v5/slobs/stream-labels/app-settings/${platform}`,
  );
};

//
//
//

const mapWidgetKeys: Record<WidgetThemeType, string[]> = {
  alertbox: ["alert_box"],
  chatbox: ["chat_box"],
  credits: ["end_credits"],
  donationticker: ["donation_ticker"],
  eventlist: ["event_list"],
  goals: [
    "donation_goal",
    "follower_goal",
    "bit_goal",
    "sub_goal",
    "stars_goal",
    "supporter_goal",
  ],
  streamboss: ["streamboss"],
  tipjar: ["tip_jar"],
  viewercount: ["viewer_count"],
};

export const fetchInstallWidgetTheme = (
  widgetTheme: WidgetTheme,
  widgets?: WidgetThemeType[],
) => {
  let alertProfileUrl = widgetTheme.alert_profile_url;

  console.log({ alertProfileUrl, widgets });

  if (widgets && alertProfileUrl.includes("/multi/")) {
    const u = new URL(alertProfileUrl);
    u.searchParams.delete("widgets");

    const separator = u.searchParams.size > 0 ? "&" : "?";

    const widgetsQueryParam = widgets
      .map((k) => mapWidgetKeys[k])
      .flat()
      .join(",");

    alertProfileUrl = u.toString() + `${separator}widgets=${widgetsQueryParam}`;
  }

  return useStreamlabsFetch<unknown>(
    `/api/v5/obs-plugin/library/install/widget-theme`,
    {
      method: "POST",
      body: {
        alert_profile_url: alertProfileUrl.toString(),
        name: widgetTheme.name,
        id: widgetTheme.id,
      },
    },
  );
};

// https://streamlabs.com/api/v5/library/fetch?type=overlays&ids=1253%2C1252%2C1254%2C1200%2C1232%2C1086%2C1202&includeFree=true

export const fetchOverlaysForDesigner = (designer: Designer) =>
  useFetch<StreamlabsOverlaysResponse>(
    "https://overlays.streamlabs.com/api/overlays",
    {
      query: {
        approval_status: "approved",
        designer: designer.id,
        most_installed: false,
        newly_added: true,
        trending: false,
        limit: 100,
        // prime: 0,
      },
    },
  );
//   return useStreamlabsFetch<WidgetProfilesResponse>(`/api/v5/library/fetch`, {
//     query: {
//       type,
//       ids: "1253",
//     },
//   });
// };
export const useStreamlabsApi = () => {
  const wrap =
    <Data, Error>(d: AsyncData<Data, Error>) =>
    () => {
      !["pending", "success"].includes(d.status.value) && d.execute();
      return d;
    };

  const fetchOverlaysBrowse = useFetch<StreamlabsOverlaysBrowseResponse>(
    "https://overlays.streamlabs.com/api/overlays/browse",
    {
      immediate: false,
      lazy: true,
    },
  );

  const fetchFreeOverlays = useFetch<StreamlabsOverlaysResponse>(
    "https://overlays.streamlabs.com/api/overlays",
    {
      immediate: false,
      lazy: true,
      query: {
        approval_status: "approved",
        most_installed: false,
        newly_added: false,
        trending: true,
        limit: 20,
        prime: 0,
      },
    },
  );

  return {
    fetchOverlaysBrowse: wrap(fetchOverlaysBrowse),
    fetchFreeOverlays: wrap(fetchFreeOverlays),
  };
};

export default useStreamlabsApi();

// export const fetchOverlaysBrowse = () =>
//   useFetch<StreamlabsOverlaysBrowseResponse>(
//     "https://overlays.streamlabs.com/api/overlays/browse",
//   );

export const fetchWidgetThemes = () =>
  useFetch<StreamlabsOverlaysResponse>(
    "https://overlays.streamlabs.com/api/widget-themes",
    {
      query: {
        approval_status: "approved",
        most_installed: false,
        newly_added: false,
        trending: true,
        limit: 20,
        prime: 0,
      },
    },
  );

export const fetchWidgetThemesBrowse = () =>
  useFetch<StreamlabsOverlaysBrowseResponse>(
    "https://overlays.streamlabs.com/api/widget-themes/browse",
  );

export const fetchWidgetTheme = (id: string | number) =>
  useFetch(`https://overlays.streamlabs.com/api/widget-theme/${id}`, {
    transform(data: { data: WidgetTheme }) {
      return data.data;
    },
  });

export const fetchOverlay = (id: string | number) =>
  useFetch(`https://overlays.streamlabs.com/api/overlay/${id}`, {
    transform(data: { data: Overlay }) {
      return data.data;
    },
  });

export const fetchLibraryItemBundles = (
  id: string | number,
  field: "overlay_id" | "widget_theme_id",
) =>
  useFetch<StreamlabsOverlaysBundlesResponse>(
    `https://overlays.streamlabs.com/api/bundle/get`,
    {
      params: {
        field,
        value: id,
      },
    },
  );

export const fetchPkceCredentials = (verifier: string, code: string) =>
  useFetch<{ data: AuthResponseParams }>(
    `${baseUrl.value}/api/v5/obs-plugin/auth/data`,
    {
      params: {
        code_verifier: verifier,
        code,
      },
    },
  );

export const loginUrl = computed(() => `${baseUrl.value}/obs-plugin/login`);
